import { red } from "@material-ui/core/colors";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  primaryGradient: {
    main: "radial-gradient(circle at bottom left, #E38F52, #7E43A6, #5F12E2)"
  },
  borderRadius: 10,
  palette: {
    type: "light",
    primary: {
      main: "#481CBD"
    },
    secondary: {
      main: "#FFC107"
    },
    error: {
      main: red.A400
    },
    background: {
      default: "#fff"
    }
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"],
    subtitle1: {
      fontSize: "14pt"
    },
    h1: {
      fontWeight: 800
    },
    h2: {
      fontWeight: 700
    },
    h4: {
      fontWeight: 900,
      fontSize: "42pt"
    }
  }
});

export default responsiveFontSizes(theme);
